import styled from 'styled-components/macro';

export const ContainerDiv = styled.div`
  background-color: white;
  height: 100vh;
  flex: 1;
  width: 100vw;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (min-width: 575px) {
    background-color: #faf8f7;
    display: flex;
  }

  @media (max-height: 500px) {
    display: unset;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;

  * {
    box-sizing: border-box;
  }

  @media (min-width: 575px) {
    height: 579px;
    flex-direction: row;
  }

  @media (max-width: 575px) {
    margin-bottom: 24px;
  }
`;

export const InvitationWrapper = styled.div`
  * {
    box-sizing: border-box;
  }
`;
